import React from 'react';

const ListItem = ({url, image, title, description}) => (
	<a href={ url } className="listItem" target="_blank" rel="noopener noreferrer">
		<img src={image} alt={title}/>
		<div className="infos">
			<h3 className="post-title">{title}</h3>
			<div className="desc">{description}</div>
		</div>
	</a>
);

export default ListItem;