import React from "react";
import Layout from '../components/layout';
import profile from "../images/profile.jpg";
import { graphql, Link } from 'gatsby';
import Post from '../components/Post';
// import { TwitterFollowButton } from 'react-twitter-embed';

import GitHubButton from 'react-github-btn'

import ListItem from '../components/ListItem';
import floppy from '../images/floppy.png';

const Home = ({ data }) => (
  <Layout title="HomePage">
    <div className="presentaion">
		<div className="profile">
			<img src={profile} alt="@Bnjis"/>
			<GitHubButton href="https://github.com/Bnjis" data-size="large" aria-label="Follow @ntkme on GitHub" data-show-count="true">Follow @Bnjis</GitHubButton>
			{/* <TwitterFollowButton screenName={'blienart'} data-show-count="false" /> */}
		</div>

		<div className="caption">
			<h1>Hey it's Bnji </h1>
			<p>i'm Benji a full stack software developer creating project with modern JavaScript, and development.</p>
		</div>
	</div>

	<div className="homePostList homeBloc">
		<div className="titleBar">
			<h2>Lastest Posts</h2>
			<Link to="/blog" title="All Blog Posts">All Posts</Link>
		</div>
		{
			data.allMarkdownRemark.edges.map(post => {
				const {date, title, author, path, featuredImage} = post.node.frontmatter;
				let featuredImgFluid = featuredImage.childImageSharp.fluid;

				return (
					<Post
						date={date}
						title={title}
						author={author}
						path={path}
						key={`${date}__${title}`}
						featuredImage={featuredImgFluid}
					/>
				)
			})
		}
	</div>
	<div className="homeBloc">
		<div className="titleBar">
			<h2>Personnal & Open Source Projects</h2>
			<a href="https://github.com/Bnjis" target="_blank" title="Open Source Projects" rel="noopener noreferrer">Go GitHub</a>
		</div>
		<div class="list">
			<ListItem
			url="https://howmanyfloppydisk.bnjis.now.sh/"
			image={floppy}
			title="How Many floppy disk"
			description="Une description pour le site des disquette en attente de mise en ligne"
			/>
		</div>
	</div>

	{console.log('data',  data)}
  </Layout>
);


// query Notion {
// 	notionContent(id: { eq: "68117e62-4749-43d8-b775-3f96e842052f" }) {
// 	  id
// 	  contentType
// 	  internal {
// 		# ... other properties of internal
// 		content
// 	  }
// 	}
//   }
export const HomeQuery = graphql`
	query HomeQuery {
		allMarkdownRemark(limit: 5) {
			edges {
				node {
					frontmatter {
						date(formatString: "DD/MM/YYYY")
						title
						author
						path
						featuredImage {
							childImageSharp {
								fluid(maxWidth: 300) {
									...GatsbyImageSharpFluid
								}
							}
						}
					}
				}
			}
		}
	}
`

export default Home;